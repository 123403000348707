import React from 'react';

import './LoadingCards.scss';


function LoadingCards({count}) {

    let loadingCards = [];

    for(let i = 0; i < count; i++){
        loadingCards.push(<div key={i} className="loadingCard" id="gradient"></div>)
    }

    return (
        <>
           {loadingCards}
        </>
    );
}

export default LoadingCards;