import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

function PasswordInput({handleChange, errorsArr, setErrorsArr}) {

    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const validatePassword = (password) => {

        if(password.length < 8){
            setError(true);
            setHelperText("Password must be atleast 8 characters long.")
            let tempErrorsArr = errorsArr.filter(i =>  i !== 'password');
            setErrorsArr([...tempErrorsArr, "password"])
        } else {
            setError(false);
            setHelperText("")
            let tempErrorsArr = errorsArr.filter(i =>  i !== 'password');
            setErrorsArr(tempErrorsArr)
        }
    }

    return (
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                type="password"
                error={error}
                helperText={helperText}
                onChange={handleChange}
                onBlur={(e) => validatePassword(e.target.value)}
            />
      </Grid>
    );
}

export default PasswordInput;