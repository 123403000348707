import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import footerLogo from "../../assets/images/footer-logo.svg"
import "./Footer.scss"
import skype from "../../assets/images/skype.svg"
import mIcon from "../../assets/images/m-icon.svg"
import linkedin from "../../assets/images/linkedin.svg"

function Copyright() {
  return (
    <Typography className='copywrite' variant="body2" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.codetrack.dev/">
        CodeTrack.dev
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Footer(props) {
  return (
    <div className="footer">
      <div className='conatiner'>
        <div className='nav-logo-wrapper'>
          <Link to="/" className='footer-logo'>
            <img src={footerLogo} alt="logo" />
          </Link>
          <ul className='nav-items'>
            <li className='nav-link'><Link to="">Bootcamps</Link></li>
            <li className='nav-link'><Link to="">Universities</Link></li>
            <li className='nav-link'><Link to="">How To Use</Link></li>
          </ul>
        </div>
        <ul className='social-icons'>
          <li className='social-link'><Link to=""><img src={skype} alt='social icon' /></Link></li>
          <li className='social-link'><Link to=""><img src={mIcon} alt='social icon' /></Link></li>
          <li className='social-link'><Link to=""><img src={linkedin} alt='social icon' /></Link></li>
        </ul>
      </div>
      <Copyright />
    </div>
  );
}

export default Footer;