import React, {useState} from 'react';
import { useHistory, useLocation, Link } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Snackbar from '@mui/material/Snackbar';

import EmailInput from '../components/inputs/EmailInput';
import PasswordInput from '../components/inputs/PasswordInput';
import SecretCodeInput from '../components/inputs/SecretCodeInput';


import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      width: '100%'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    }
  }));

function CreatePassword({subdomain}) {

    let orgConfig = {};

    if(subdomain){
      orgConfig = require('../orgConfig/pursuit.json');
    }

    const classes = useStyles();
    const location = useLocation();
    let history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorsArr, setErrorsArr] = useState([]);
    const [toastNote, setToastNote] = useState(location?.state?.toastMessage);
    const [showToast, setShowToast] = useState(location?.state?.toastMessage);

    const handleChange = (e) => {
        if(e.target.name === "email"){
          setEmail(e.target.value);
        } else if (e.target.name === 'password') {
          setPassword(e.target.value)
        } else {
          setSecurityCode(e.target.value)
        }
      }

      const handleSubmit = (e) => {
    
        e.preventDefault();
  
        if(errorsArr.length) {
          setErrorMessage("Please resolve inputs before submitting.");
          window.scrollTo(0, 0);
          return;
        }
      
        if(!password || !email || !securityCode) {
          setErrorMessage("Please provide required values.");
          window.scrollTo(0, 0);
          return;
        }

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password, securityCode})
        };
          
        fetch(`${process.env.REACT_APP_API}/auth/addPassword`, requestOptions)
        .then(res => res.json())
        .then((result) => {
          if(result.error){
            setErrorMessage(result.error);
          } else {
            history.push({
              pathname: '/login',
              state: { toastMessage: "Your password has been added! Please log in." }
            })
          }        
        },
         
          (error) => {
            console.log(error);
          }
        )
      }

    return (
        <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
             <Snackbar
                open={showToast}
                autoHideDuration={2000}
                onClose={() => setShowToast(!showToast)}
                message={toastNote}
                anchorOrigin={{'vertical': 'top', 'horizontal': 'center'}  }
            />
             <Avatar className={classes.avatar}>
          </Avatar>
            <Typography component="h1" variant="h5">
                Add A Password
            </Typography>
            {errorMessage &&
              <div style={{"color" : "red"}}>  {errorMessage}</div>
            }
            <p style={{"fontWeight" : "600"}}>
               Note: you will need a CodeTrack security code. If you don't have one, get one <Link to={'/generateCode'}><span style={{"fontSize": "20px" }} >here</span></Link>.
            </p>
            <form className={classes.form} noValidate>
            <Grid container spacing={2}>
                <EmailInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr} orgConfig={orgConfig} />
                <PasswordInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr}/>             
                <SecretCodeInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr}/>             
              </Grid>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Add
              </Button>
              </form>
            </div>
            <Box mt={5}>
            </Box>
        </Container>
    );
}

export default CreatePassword;