import React, {useEffect, useState} from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './BarGraph.scss';

function getPreviousSunday(date = new Date(), weeksAgo = 0) {
  
    const previousSunday = new Date();
  
    let dayOfMonth = date.getDate();
    let dayOfWeek =  date.getDay();
    
    if(dayOfWeek == 0){
      dayOfMonth -= 1;
      dayOfWeek = 6;
    }
    
    previousSunday.setDate(dayOfMonth - (dayOfWeek) - (7 * weeksAgo))
  
    const previousSundayString = previousSunday.toISOString();
  
    const parsedDate = previousSundayString.split('T')[0].split('-').slice(1).join('/');
  
    const dateWithLeadingZerosRemoved = parsedDate[0] == '0' ? parsedDate.slice(1) : parsedDate;
    
    return dateWithLeadingZerosRemoved;
}


function BarGraph({display=true, userid, playerCard, setShowGraph, weeks=8}) {

    const [pointsData, setPointsData] = useState([]);
    const [commitData, setCommitData] = useState([]);
    const [max, setMax] = useState(160);

    let rows = [0,1,2,3,4];
    let cols = [];
    
    for(let i = 0; i <= weeks+1; i++ ){
        cols.push(i);
    }

    let weeksAgoArr = cols.slice(0, -2).reverse();
    
    let dataEntries = cols.slice(0, -2); 

    useEffect(() => {
        fetchPointsData();
    }, [])

    useEffect(() => {

        const graphData = [...pointsData, ...commitData];

        if(graphData.length)
            setMax(findMax(graphData));
        else {
            setMax(160);
        }

    }, [pointsData, commitData]);

    const fetchPointsData = () => {

        if(pointsData.length && commitData.length){
            setPointsData([]);
        } else if (!pointsData.length) {
            const url = `${process.env.REACT_APP_API}/users/${userid}/weekly_scores`;

            fetch(url)
            .then(response => response.json())
            .then(data => {
                
                while(data.length < weeks){
                    data.push({points: 0});
                }
                setPointsData(data.slice(0, weeks).reverse());
            })
        }
    }

    const fetchCommitData = () => {
        if(commitData.length && pointsData.length){
            setCommitData([])
        } else if (!commitData.length) {
            const url = `${process.env.REACT_APP_API}/users/${userid}/weekly_commits`;

            fetch(url)
            .then(response => response.json())
            .then(data => {


                while(data.length < weeks){
                    data.push({points: 0});
                }
                
                setCommitData(data.slice(0, weeks).reverse());
            })
        }
    }

    const findMax = (dataArr) => {
        let max = 4;
        
        for(let data of dataArr){
            max = Math.max(data.points, max);
        }

        return max;
    }

    const closeBarGraph = (e) =>{
        e.stopPropagation();
        e.preventDefault();

        setShowGraph(false);
    }

    return (
        <div className="barGraph" data-testid="barGraph" onClick={(e)=>{e.stopPropagation(); e.preventDefault()}} style={{"display" : display ? "block" : "none"}}>
            <div className="barGraph__menu" >
                <div className="barGraph__dataTypes">
                    <div className={pointsData.length > 0 ? 'barGraph__togglePoints-active' : "barGraph__togglePoints"} onClick={() => fetchPointsData() }>Points</div>
                    <div className={commitData.length > 0 ? 'barGraph__toggleCommits-active' : "barGraph__toggleCommits"} onClick={() => fetchCommitData() }>Commits</div>
                </div>
                <div className="barGraph__settings">
                    {playerCard && <div>
                        <FontAwesomeIcon icon={faTimes} onClick={(e) => closeBarGraph(e)}/>
                    </div>}
                </div>
            </div>
            <div className="barGraph__container">
                <div className="barGraph__scale" style={{"width" : playerCard ? "10%" :"3%"}}>
                    <div>{Math.floor(max)}</div>
                    <div>{Math.floor(max * .75)}</div>
                    <div>{Math.floor(max * .5)}</div>
                    <div>{Math.floor(max * .25)}</div>
                    <div>0</div>
                </div>
                <div className="barGraph__background"  style={{"width" : "100%"}}>
                    {rows.map(i => {
                        return (
                            <div className="barGraph__row">
                            {cols.map(j => {
                                return (
                                    <div className="barGraph__backgroundCell"></div>
                                )
                            })}
                        </div>
                        )
                    })}
                 
                </div>

            </div>
            <div className={(pointsData.length && commitData.length) ? "barGraph__bars both" : "barGraph__bars"}>

                {
                    dataEntries.map(entry => {
                        return (
                            <>
                                {pointsData.length > 0 &&
                                    <Tooltip title={pointsData[entry].points} arrow placement="top">
                                        <div className="barGraph__bar points" style={{"height" : Math.ceil((pointsData[entry].points / max) * 150)}}></div>
                                    </Tooltip>
                                }
                                {commitData.length > 0 && 
                                    <Tooltip title={commitData[entry].points} arrow placement="top">
                                        <div className="barGraph__bar commits" style={{"height" :  Math.ceil((commitData[entry].points / max) * 150)}}></div>
                                    </Tooltip>
                                }
                            </>
                        )
                    })
                }
            </div>
            <div className="barGraph__dates" style={{"padding-left": playerCard ? "40px" : "58px"}}>
                {
                    weeksAgoArr.map(weeksAgo => {
                        return (<div style={{"padding-right": playerCard ? "3%" : "3.4%"}}>{getPreviousSunday(new Date(), weeksAgo )}</div>)
                    })
                }
            </div>
        </div>
    );
}

export default BarGraph;