import React from 'react';

import CommitsLog from '../../components/commitsLog/CommitsLog';
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

import './StudentDetailSubviews.scss';

function Portfolio({playerInfo}) {
    console.log(playerInfo)
    return (
        <ShadowBox className="portfolio" title="Portfolio View">
           <CommitsLog username={playerInfo.github_username}/> 
        </ShadowBox>
    );
}

export default Portfolio;