import React from 'react';

import { useHistory } from "react-router-dom";

import SelectorCard from '../selectorCard/SelectorCard';

import './ClassSelector.scss';

function ClassSelector({subdomain, cohorts, setCohorts}) {

    let history = useHistory();

    let orgConfig = {};

    if(subdomain){
      orgConfig = require(`../../orgConfig/${subdomain}.json`);
    }

    let options = orgConfig.classes;

    const toggleCohort = (add, cohort) => {

        if(add){
            setCohorts([cohort, ...cohorts])
            var newurl = '?cohort=' + [cohort, ...cohorts].toString();
            history.replace(newurl);
        } else {
            let filterList = [];
            for(let i of cohorts){
                if(i != cohort)
                    filterList.push(i)
            }
            setCohorts(filterList)
            var newurl = filterList.length ? '?cohort=' + filterList.toString() : '';
            history.replace(newurl);

        }
       
    }

    return (
        <div className="classSelector">
            <div className="classSelector__title">Cohorts by Class</div>
            {options.map((option, index) => {
                return (<SelectorCard action={(add, option) => toggleCohort.call(this, add, option)} text={option} key={index} options={options} cohorts={cohorts}/>)
            })}
            
        </div>
    );
}

export default ClassSelector;