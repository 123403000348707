import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './Modal.scss';

const Modal = ({setShowModal, showModal, title, children}) => {

  const hideModal = function() {
    setShowModal(!showModal);
  }
  let displaySyling = {display: showModal ? "block" : "none"};
  return (
    <>
      <div className="modal__overlay" onClick={hideModal} style={displaySyling}></div>
      <div className="modal" style={displaySyling}>
        <div className="modal__closeIcon" onClick={hideModal}>
          <FontAwesomeIcon icon={faTimes}  size="lg" />
        </div>
        <div className="modal__title"> {title} </div>
        <div className="modal__content">
          { children }
        </div>
      </div>
    </>
  )
}

export default Modal;