import React from 'react';

import './PlayerStats.scss';

function PlayerStats({playerInfo, title, type}) {

    return (
        <div className="playerStats">
            <span className="playerStats__title">{title}</span>
            <div className="playerStats__lastWeek">
                 <span className="playerStats__points">{type === 'codewars' ? playerInfo.points_this_week : playerInfo.commits_this_week }</span>
                 <div className="playerStats__lastWeekLabel" >This Week</div>
            </div>
            <div className="playerStats__lastMonth">
                <span className="playerStats__points">{type === 'codewars' ? playerInfo.points_this_month : playerInfo.commits_this_month }</span>
                <div className="playerStats__lastMonthLabel" >This Month</div>
            </div>
        </div>   
    );
}

export default PlayerStats;