import React, {useState, useEffect} from 'react';
import { useParams} from "react-router-dom";

import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import CodeChallenge from '../../components/codeChallenge/CodeChallenge';


import './EditProblemSet.scss';
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

const EditProblemSet = (props) => {

    const {problemSetId} = useParams(); 

    const [problemSet, setProblemSet] = useState({problems:[]});
    const [title, setTitle] = useState("");

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API}/problemsets/${problemSetId}`)
        .then(response => response.json())
        .then(data => {
            console.log(data)
            setProblemSet(data);
            setTitle(data.title);
        })
    }, [])

    const refreshProblems = () => {

    }

    const problemElements = problemSet.problems.map(problem => {
        return ( <CodeChallenge problem={problem} problemSetId={problemSetId} refreshProblems={refreshProblems}  />)
    })

    const updateTitle = (e) => {
        setTitle(e.target.value);
    }

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const saveChanges = () => {
        
    }

    return (
        <div className="editProblemSet">
            <div className='conatiner'>

            <h3>Edit Problem Set</h3>
            <div className="twoColumns">
                <TextField id="set-title" value={title} label="Title" variant="outlined" onChange={(e) => updateTitle(e)} />
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={() => saveChanges()}
                >
                    Save Changes
                </Button>
            </div>
            <div className="twoColumns">
                <div>
                    {problemElements}
                </div>
                <ShadowBox title="Assign to:">
                    <FormControlLabel control={<Checkbox  />} label="Pursuit-8.1" />
                    <FormControlLabel control={<Checkbox  />} label="Pursuit-9.3" />
                    <FormControlLabel control={<Checkbox />} label="Pursuit-9.4" />
                </ShadowBox>
            </div>           
        </div>
        </div>
    )
}

export default EditProblemSet;