import React, {useState} from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

function SecretCodeInput({handleChange, errorsArr, setErrorsArr}) {

    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    const validateCode = (code) => {

        if(code.length !== 6 ||  !(/[0-9]{6}/.test(code))){
            setError(true);
            let tempErrorsArr = errorsArr.filter(i =>  i !== 'code');
            setErrorsArr([...tempErrorsArr, "code"])
            setHelperText("Your secret code is made of only 6 digits.")
            return;
        } 
        
        setError(false);
        setHelperText(""); 
        let tempErrorsArr = errorsArr.filter(i =>  i !== 'code');
        setErrorsArr([...tempErrorsArr])    
    }

    return (
        <Grid item xs={12}>
            <TextField
                variant="outlined"
                required
                fullWidth
                id="securityCode"
                label="CodeTrack Security Code"
                name="securityCode"
                error={error}
                helperText={helperText}
                onChange={handleChange}
                onBlur={(e) => validateCode(e.target.value)}
            />
        </Grid>
    );
}

export default SecretCodeInput;