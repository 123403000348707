import React from 'react';

function HowToUse(props) {
    return (
        <div className="how-to-use">
            <div className='conatiner'>

                <h2>What is CodeTrack and How Does It Work?</h2>
                <p>
                    Codewars is a platform for practicing algorithmic problems in several different languages. It
                    offers a great way to practice both the fundamentals and advanced aspects of any common language.
                </p>
                <p>
                    This application is set up to help you track your progress and consistency in working with Codewars.
                    Every time you complete a problem, an event will be emitted that updates your total poins, your weekly
                    points and your monthly points.
                </p>

                <h3>First, you should create an account with <a href="https://codewars.com" target="_blank">Codewars</a>. It is free and very quick.</h3>

                <h3>After you create your account, add the webhook by following these steps:</h3>

                <ol>
                    <li>Log into your Codewars account and navigate to <a href="https://www.codewars.com/users/edit" target="_blank">My Account</a></li>
                    <li>Scroll down to the section that is titled: Webhooks.</li>
                    <img style={{ "width": "100%" }} src="/codewars_webhook_screenshot.png" />
                    <li>Copy and paste this following URL into the 'Payload url' box: https://codewars-tracker-be.herokuapp.com/codewars</li>
                    <li>Click on the 'Update' button at the bottom of the page.</li>
                </ol>

                <h3> <a href="/createAccount">Create an account on this platform</a></h3>

                <h3>You're Good to Go!</h3>
                <p>Go ahead and start solving problems. You can practice alone or pair with others - hopefully both!</p>
            </div>
        </div>
    );
}

export default HowToUse;