import React from 'react';

import "./EmptyView.scss";

function EmptyView({text}) {

    return (
        <div className="emptyView">
            {text ? text : "No Items"}
        </div>
    );
}

export default EmptyView;