import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCross, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Link } from 'react-router-dom';
import './Navbar.scss';
import skype from "../../assets/images/skype.svg"
import mIcon from "../../assets/images/m-icon.svg"
import linkedin from "../../assets/images/linkedin.svg"
import Typography from '@mui/material/Typography';

const NavBarLink = (props) => {

    return (
        <NavLink to={props.url} className={props.class} onClick={props.onClick} >
            <li className={props.classNames}>
                {props.children}
            </li>
        </NavLink>
    )
}

const Navbar = ({ menuData, loggedIn, setLoggedIn, expandMenu, setExpandMenu }) => {

    let history = useHistory();
    let location = useLocation();

    let { menuItems, logo } = menuData;

    menuItems = menuItems.filter(menuItem => {
        return !("loggedIn" in menuItem) || menuItem.loggedIn == loggedIn;
    })

    const logoutUser = () => {
        localStorage.removeItem("accessToken");
        setLoggedIn(false);
        setExpandMenu(false);
        history.push('/')
    }

    const toggleMenu = () => {
        setExpandMenu(!expandMenu)
    }

    const menuItemElements = menuItems.map((menuItem, index) => {
        if (menuItem.text === 'Log Out') {
            return (
                <li key={index} onClick={() => logoutUser()}>
                    {menuItem.text}
                </li>
            )
        }

        return (
            <NavBarLink key={index} classNames={menuItem.class} url={menuItem.url} onClick={() => setExpandMenu(false)}>
                {menuItem.text}
            </NavBarLink>
        )
    })

    return (
        <nav>
            <div className="conatiner">
                <div className="nav-items">

                    <Link to="/" onClick={() => setExpandMenu(false)}>

                        {logo.src &&
                            <div className="logo_container left">
                                <img src={logo.src} className="logo" alt="Logo" />
                            </div>
                        }
                        {logo.text &&
                            <span className="logo left">{logo.text}</span>
                        }
                    </Link>


                    <div className="condensed-menu-icon right" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={faBars} />
                        {/* <FontAwesomeIcon icon={faCross} /> */}
                    </div>

                    <ul className={"expanded-menu-items right " + (expandMenu ? "active" : "")} >
                        {menuItemElements}
                        <div className="mobile-menu">
                            <div className="navigation-link">
                                <div className="register">
                                    <ul className='nav-items'>
                                        <li className='nav-link'><Link onClick={toggleMenu} to="/login">Log In</Link></li>
                                        <li className='nav-link'><Link onClick={toggleMenu} to="/createAccount">Register for Free </Link></li>
                                    </ul>
                                </div>
                                <div className="pages">
                                    <ul className='nav-items'>
                                        <li className='nav-link'><Link onClick={toggleMenu} to="/learnMore">Bootcamps</Link></li>
                                        <li className='nav-link'><Link onClick={toggleMenu} to="/learnMore">Universities</Link></li>
                                        <li className='nav-link'><Link onClick={toggleMenu} to="/howToUse">How To Use</Link></li>
                                    </ul>
                                </div>
                                <ul className='social-icons'>
                                    <li className='social-link'><Link onClick={toggleMenu} to=""><img src={skype} alt='social icon' /></Link></li>
                                    <li className='social-link'><Link onClick={toggleMenu} to=""><img src={mIcon} alt='social icon' /></Link></li>
                                    <li className='social-link'><Link onClick={toggleMenu} to=""><img src={linkedin} alt='social icon' /></Link></li>
                                </ul>

                            </div>
                            <Typography className='copywrite' variant="body2" align="center">
                                {'Copyright © '}
                                <Link color="inherit" href="https://www.codetrack.dev/">
                                    CodeTrack.dev
                                </Link>{' '}
                                {new Date().getFullYear()}
                                {'.'}
                            </Typography>
                        </div>
                    </ul>
                </div>
            </div>

        </nav>
    )
}


export default Navbar;