import React from 'react';

import './ShadowBox.scss';

function ShadowBox({title, children, className, flex=false}) {
    
    const displayStyle = flex ? 'flex' : 'block';

    return (
        <div className={className + " shadowBox"}>
            {title && <div className="shadowBox__title">{title}</div>}
            <div style={{"display": displayStyle}}>
                {children}
            </div>
        </div>
    );
}

export default ShadowBox;