
import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import NameInput from '../components/inputs/NameInput';
import EmailInput from '../components/inputs/EmailInput';
import PasswordInput from '../components/inputs/PasswordInput';

import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: "10px 0 !important",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    }
  }));

function CreateAccount({setLoggedIn, value, subdomain}) {

    let history = useHistory();
    let orgConfig = {};

    if(subdomain){
      orgConfig = require(`../orgConfig/${subdomain}.json`);
    }
    
    const [role, setRole] =useState("fellow");
    const [name, setName] =useState("");
    const [email, setEmail] =useState("");
    const [password, setPassword] =useState("");
    const [username, setUsername] =useState("");
    const [cohort, setCohort] =useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorsArr, setErrorsArr] = useState([]);
    const [loading, setLoading] = useState(false);
     
    const classes = useStyles();

    const handleChange = (e) => {
        e.preventDefault();

        let name = e.target.name;
        let value = e.target.value;

        switch(name) {
            case 'role':
                setRole(value);
                break;
            case 'name':
                setName(value);
                break; 
            case 'password':
                setPassword(value);
                break;
            case 'username':
                setUsername(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'cohort':
                setCohort(value);
                break;
            default: 
                break;
        }

  }

  const handleSubmit = (e) => {
    
    e.preventDefault();
    setLoading(true);

    if(errorsArr.length) {
      setErrorMessage("Please resolve inputs before submitting.");
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }

    if(!name || !password || !email) {
      setErrorMessage("Please provide required values.");
      window.scrollTo(0, 0);
      setLoading(false);
      return;
    }

    const organization = orgConfig.orgName ? orgConfig.orgName : 'codetrack';

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, password, role, cohort, username, organization})
    };


    fetch(`${process.env.REACT_APP_API}/auth/register`, requestOptions)
    .then(res => res.json())
    .then((result) => {

        setLoading(false);

        if(result.error){
          setErrorMessage(result.error)
          window.scrollTo(0, 0);
        } else {
          setLoggedIn(true)
          if(subdomain && result.organization != subdomain){
            let uri = new URL(`http://pursuit.codetrack.dev?accessToken=${result.accessToken}`);
            window.location.assign(uri.href);
          } else {
            localStorage.setItem('accessToken', result.accessToken);
            history.push({
              pathname: '/',
              state: { toastMessage: "Account successfully created!" }
            })
          }
           
        }
      },
     
      (error) => {
        setLoading(false);
        console.log(error);
      }
    )
  }

   
    return (
        <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          {errorMessage &&
            <div style={{"color" : "red"}}>  {errorMessage}</div>
          }
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <NameInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr}/>
              <EmailInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr} orgConfig={orgConfig} />
              <PasswordInput handleChange={handleChange} errorsArr={errorsArr} setErrorsArr={setErrorsArr}/>

              <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    name="username"
                    label="Codewars Username"
                    id="username"
                    onChange={handleChange}
                />
              </Grid>
              {orgConfig.classes &&
                <FormControl variant="outlined" className={classes.formControl} fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">Your Class</InputLabel>
                  <Select
                    variant="outlined"
                    required
                    name="cohort"
                    label="Your Class"
                    id="cohort"
                    onChange={handleChange}
                  >
                    {
                      orgConfig.classes.map((item, index) => {
                        return (<MenuItem key={item+index} value={item}>{item}</MenuItem>)
                      })
                    }
                  </Select>
                </FormControl>
              }
              {subdomain && 
                <Grid item xs={12}>
                <RadioGroup aria-label="role" name="role" defaultValue="fellow" value={value} onChange={handleChange}>
                    <FormControlLabel value="fellow" default control={<Radio />} label="Fellow" />
                    <FormControlLabel value="staff" control={<Radio />} label="Staff" />
                </RadioGroup>
                </Grid>
              }
            </Grid>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleSubmit}
              loading={loading}
            >
              Sign Up
            </LoadingButton>
          </form>
        </div>
        <Box mt={5}>
        </Box>
      </Container>
    );
}

export default CreateAccount;



  