import React, {useState, useEffect} from 'react';


import badgeData from '../../data/badges.json';

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import SingleBadge from '../singleBadge/SingleBadge';

import './BadgeList.scss';

function BadgeList({userId}) {
    const [badges, setBadges] = useState([]);

    useEffect(() => {
        if(userId){
            const url = `${process.env.REACT_APP_API}/badges/${userId}`
            fetch(url)
            .then(response => response.json())
            .then(data => {
                setBadges(data);
            })
        }
    }, [userId]);

    return (
        <ShadowBox  className="badgeList" title="Badges">
            <div className="badgeList__container">
                {Object.entries(badges).map((badge, index) => {

                    if(badge[0] === 'id' || badge[0] === 'user_id')
                        return;

                    let badgeKey = badge[0];
                    
                    if(!badge[1])
                        badgeKey += "Gray"

                    return (
                        <SingleBadge key={index} badge={badgeData[badgeKey]} />
                    )
                })}
            </div>
        </ShadowBox>
    );
}

export default BadgeList;