import React, {useState, useEffect, Suspense} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import queryString from 'query-string';

import Snackbar from '@mui/material/Snackbar';

import TopScoresCollection from '../components/topScoresCollection/TopScoresCollection';
import PlayerCardCollection from '../components/playerCardCollection/PlayerCardCollection';
import ScoreBoard from '../scoreBoard/ScoreBoard';
import ClassSelector from '../components/classSelector/ClassSelector';

const StaffCardCollection = React.lazy(() => import('../components/staffCardCollection/StaffCardCollection'));

function Home({subdomain, loggedIn, setLoggedIn}) { 
    let history = useHistory();

    const location = useLocation();

    const parsed = queryString.parse(location.search);

    if(parsed && parsed.accessToken){
      localStorage.setItem('accessToken', location.search.split('=')[1]);
      setLoggedIn(true);
      history.push('/') 
    }

    const [fellows, setFellows] = useState([]);
    const [cohorts, setCohorts] = useState([]);
    const [employedFellows, setEmployedFellows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [toastNote, setToastNote] = useState(location?.state?.toastMessage);
    const [showToast, setShowToast] = useState(location?.state?.toastMessage);
    
    let organization = subdomain || 'codetrack'
    let url = `${process.env.REACT_APP_API}/users/organization/${organization}`;

    useEffect(() => {
        setLoading(true);
        fetch(url)
        .then(res => res.json())
        .then((result) => {  
            setFellows(result);
            setLoading(false) ;
        },
         
          (error) => {
            setLoading(false) 
            console.log(error);
          }
        )
    }, []);

    useEffect(() => {
        if(window.location.search){
            let cohorts = window.location.search.split('=')[1].split(',');
            setCohorts(cohorts);
        }
    }, [])
    
    let handleEmployed = ([ ...fellows ].concat(employedFellows)).sort((a,b) => a.honor > b.honor ? -1 : 1);

    
    let selectedCohorts = cohorts.length ? handleEmployed.filter(fellow => cohorts.includes(fellow.cohort)) : handleEmployed;

    return (
        
                <div>
                     <Snackbar
                        open={showToast}
                        autoHideDuration={2000}
                        onClose={() => setShowToast(!showToast)}
                        message={toastNote}
                        anchorOrigin={{'vertical': 'top', 'horizontal': 'center'}  }
                    />
                    <ScoreBoard subdomain={subdomain} />
                    {subdomain !== 'codetrack' &&
                        <ClassSelector subdomain={subdomain} cohorts={cohorts} setCohorts={setCohorts} />
                    }
                    <TopScoresCollection loading={loading} fellows={selectedCohorts} subdomain={subdomain}/>
                    <PlayerCardCollection cohorts={cohorts} fellows={selectedCohorts} setFellows={setFellows} setEmployedFellows={setEmployedFellows} subdomain={subdomain} />
                    
                    <Suspense fallback={<div>Loading...</div>}>
                        <StaffCardCollection cohorts={cohorts} subdomain={subdomain} />
                    </Suspense>
                      
                </div>
    );
}

export default Home;