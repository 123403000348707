import React from 'react';

import PlayerDetailCard from '../../components/playerDetailCard/PlayerDetailCard';
import PointsBreakdown from '../../components/pointsBreakdown/PointsBreakdown';
import BadgeList from '../../components/badgeList/BadgeList';
import BarGraph from '../../components/barGraph/BarGraph';
import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';

import './StudentDetailSubviews.scss';

function Dashboard({playerInfo}) {

  return (
        <>
          <div className="profileInfo">
            <PlayerDetailCard playerInfo={playerInfo} />
            <PointsBreakdown playerInfo={playerInfo} />
            <BadgeList userId={playerInfo?.id} />
          </div>
          <ShadowBox>
            {playerInfo.id && <BarGraph userid={playerInfo.id} weeks={16} />}
          </ShadowBox>   
        </>
    );
}

export default Dashboard;