import React, {useState, useEffect} from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';


import './CodeChallenge.scss';

const CodeChallenge = ({problem, problemSetId, refreshProblems}) => {

    const {title, url, difficulty, id} = problem;

    const removeProblem = (e) => {
        
        const url = `${process.env.REACT_APP_API}/problemsets/${problemSetId}/problems/${id}`;
        
        fetch(url,{method: 'DELETE'})
        .then(response => response.json())
        .then(data => {
            refreshProblems();
        })
    }

    return (
        <div className="codeChallenge">
            <Checkbox
                color="primary"
                disabled
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <a href={url} target="_blank">
                <span className="codeChallenge__title">{title} </span>
            </a>
            <div className="codeChallenge__difficulty">{difficulty}</div>
            <IconButton 
                aria-label="delete" 
                size="large"
                onClick={removeProblem}>
                <DeleteIcon />
            </IconButton>
        </div>
    )
}

export default CodeChallenge;