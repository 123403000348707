import React from 'react';
import { Link } from 'react-router-dom';


function OneGreatProject() {
    return (
        <div className="article">
            <div className="conatiner">
                <h2 className="article__title">One Amazing Application</h2>
                <p>As a new developer, your best chance to stand out in a sea of candidates is to present extremely high-quality work. The good news is, that no matter who you are or where you come from, producing amazing work is 10,000% in your control!</p>
                <p>First of all, it is our opinion that you should include one and only one project to a prospective employer. This means that even if you've worked on a few projects, you are going to only present one. The reasons we think this is important are the following:</p>
                <ol>
                    <li>Busy people will likely only look at one project. Make sure it's your best.</li>
                    <li>Producing good code that is well tested is time consuming. Don't spread your effort thin.</li>
                    <li>No one ever said, "I would have interviewed them but they only had <strong>one amazing project!</strong>" </li>
                </ol>
                <p>Ok, so hopefully we've convinced you that you should only present one great project. But what after all does a great project look like? </p>
                <h5>1. Your project should be responsive </h5>
                <p>This means it should look and work great on mobile and desktop at a range of sizes. Sometimes a project is not well suited to be fully functional on a certain size of device. That is ok, however you still need to provide a great experience for all users on any device.</p>
                <p>Here is an example: I was working on a game that teaches some javascript. Given the design, it was not meant to be fully functional on mobile devices. In this case, you could make a landing page for anyone using a mobile device that says: "This app is not configured for use on a mobile device. Can we send you a reminder to check it out on your desktop?" Then include a form that takes their email and sends a reminder. Even if someone doesn't avail themself of this feature, it demonstrates a professional approach to development where you give consideration to all users.</p>
                <h5>2. Your Project Should Look Professionally Designed.</h5>
                <p>As a developer, it is not your job to come up with a great design however as a job applicant, you distinguish your work by presenting an application that looks and feels like a fully professional site. </p>
                <p>If you don't have great design skills, consider enlisting the help of a friend, hiring a designer or 'finding inspiration' from an existing site.</p>
                <p>When I was putting together the site I used in my first interviews, I found a site whose design I admired and I let it guide me in everything I did as far as styling and colors. I am not suggesting you take credit for the design. In fact, if anyone asks, I think you should be extremely honest about how you generated the design. Good design draws people in. It is something to entice them to look at your application and code.</p>
                <h5>3. Have a Personal Connection to your Project</h5>
                <p>My One Great Project was an event management site specifically made for picnics. At that time, I hosted a weekly picnic in Central Park from May through September. It was something I really enjoyed doing and talking about. </p>
                <p>Whenever I got the opportunity to speak about the site, I explained how I hosted picnics and wanted a better way to manage RSVPs and who was bringing what. Not only would I tell people about the site and the features, I would invite them to come to a picnic sometime.</p>
                <p>Recently I spoke with a developer who was showing me a game of Cribbage he had developed. He explained that he loved the game because he had played with his family growing up. The app had been well used by him and his family to stay connected through the pandemic.</p>
                <p>Contrast this with a candidate who demonstrates a generic ecommerce site selling a fake product. It demonstrates a good level of competence but lacks any emotional appeal. Frankly, it's boring. </p>
                <p>Connect with your project and share that connection in interviews. </p>
                <h5>4. Have One Killer Feature</h5>
                <p>Let's say your project is an ecommerce project and you've implemented session management, cart, checkout and order history. That is a solid set of features. Now, go one step further and implement something unique and interesting. This might be a feature where users can customize an article of clothing through the site before adding it to their cart. Or, they can upload a photo of themselves and do a virtual try-on. Show a potential employer that you went beyond the normal scope of a project and implemented something unique.</p>
                <p>A good rule of thumb if you're wondering if a feature would meet this criteria is this: if you have to create the front end components from scratch (ie. a framework like Bootstrap wouldn't work) you're probably on the right track. </p>
                <h5>5. Well Organized Code </h5>
                <p>A professional cleans up after themself. Make sure that your code is well organized and that you've removed console.logs and other cruft. Comments should be used sparingly to explain particularly thorny blocks. Give your files names that are descriptive. Provide a readme that summarizes the project, explains how to run the project and provides your contact information.</p>
                <h5>6. Bug Free Operation</h5>
                <p>Good applications are a joy to use. Work towards making your application as nice to use as possible. Invest time in testing your code and resolving bugs.</p>
                <h5>7. In Production </h5>
                <p>It goes without saying that your project should be deployed online. Some services, like Heroku, have your server sleep unless you pay a monthly fee.  Pay the fee. When someone visits your site, they will not necessarily understand that it takes a minute for the server to start. If you want to let this lapse once you've landed a job, that's totally fine, but while you're job hunting, pay the fee.</p>
                <p>Moreover, given that you can usually find some kind of domain for $10-15/year, buy a domain that suits your application and use it. </p>
                <p>This is a non-comprehensive list to guide you in developing a great project. We offer several classes to help with different aspects of developing a project like this. Now that you have a grasp on what a great project looks like, <Link to="/landing-your-first-job" >check out the other items on our job preparation check list.</Link> </p>
            </div>
        </div>
    );
}

export default OneGreatProject;