import React, {useState} from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@mui/material/Snackbar';

import { makeStyles } from '@material-ui/core/styles';

import './ManageAcct.scss';
import ShadowBox from '../../../layoutComponents/shadowBox/ShadowBox';

const useStyles = makeStyles((theme) => ({
    submit: {
      "marginTop": '10px'    
    }
}));

function ManageAcct({playerInfo}) {

    const classes = useStyles();

    const [codewars, setCodewars] = useState(playerInfo?.username);
    const [leetcode, setLeetcode] = useState(playerInfo?.leetcodeid);
    const [github, setGithub] = useState(playerInfo?.github_username);
    const [showToast, setShowToast] = useState(false);
    const [toastNote, setToastNote] = useState("");
    const [loading, setLoading] = useState(false);


    const handleChange = (e) => {
        const {name, value} = e.target;

        switch(name) {
            case 'codewars':
                setCodewars(value);
                break; 
            case 'leetcode':
                setLeetcode(value);
                break;
            case 'github':
                setGithub(value);
                break;
            default:
                break;
        }
      }

    const handleSubmit = () => {
        setLoading(true);

        console.log(codewars, leetcode, github);


        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ${ localStorage.getItem('accessToken')}`
                    },
            body: JSON.stringify({ codewars, leetcode, github})
        };

        fetch(`${process.env.REACT_APP_API}/users/update/${playerInfo.id}`, requestOptions)
        .then(res => res.json())
        .then((result) => { 
            setLoading(false);
            setToastNote("Your account information has been updated.")
            setShowToast(true);
        }).catch(err => {
            console.error(err);
            setLoading(false);
            setToastNote("There was an error. Please try again later.")
            setShowToast(true);
        })
    }
  
    return (
        <ShadowBox className="accountSettings" title="Account Settings">
             <Snackbar
                open={showToast}
                autoHideDuration={2000}
                onClose={() => setShowToast(!showToast)}
                message={toastNote}
                anchorOrigin={{'vertical': 'top', 'horizontal': 'center'}  }
            />
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    fullWidth
                    id="codewars"
                    label="Codewars username"
                    name="codewars"
                    value={codewars}
                    onChange={handleChange}
                  />
                </Grid>
              <Grid item xs={12}>
                <TextField
                    variant="outlined"                    
                    fullWidth
                    id="leetcode"
                    label="Leetcode username"
                    name="leetcode"
                    value={leetcode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="github"
                  label="Github username"
                  name="github"
                  value={github}
                  onChange={handleChange}
                />
              </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                loading={loading}
                >
                Update
            </LoadingButton>
        </ShadowBox>
    );
}

export default ManageAcct;