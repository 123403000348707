import React, {useState, useEffect} from 'react';
import { ResponsiveBump } from '@nivo/bump';

import EmptyView from '../emptyView/EmptyView';

function BumpChart({subdomain}) {

    console.log(subdomain);

    const [standingsData, setStandingsData] = useState([]);

    useEffect(() => {

        const url = `${process.env.REACT_APP_API}/dailyStandings/${subdomain}`;
        // const url =`https://codewars-tracker-be.herokuapp.com/dailystandings/${subdomain}`;
        
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setStandingsData(data);
            });
    }, []);
    
    return (
        <div style={{"height": standingsData.length == 0 ? "auto" : "500px"}}>
        {standingsData.length == 0 && <EmptyView text={"No Standings Data"} />}
        {standingsData.length > 0 && <ResponsiveBump
            data={standingsData}
            colors={{ scheme: 'category10' }}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            pointSize={12}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{ from: 'serie.color' }}
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -36
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Ranking',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            margin={{ top: 40, right: 100, bottom: 40, left: 60 }}
            axisRight={null}
        />}
    </div>
    )
};

export default BumpChart;