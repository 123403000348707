import React, {useEffect, useState} from 'react';

import ShadowBox from '../../layoutComponents/shadowBox/ShadowBox';
import ProblemSet from '../../components/problemSet/ProblemSet';
import { Button } from '@material-ui/core';

import './ProblemSets.scss';
import { useHistory } from 'react-router-dom';

function ProblemSets(props) {

    const history = useHistory();

    const [problemSets, setProblemSets] = useState([]);
    const [yourProblemSets, setYourProblemSets] = useState([]);

    useEffect(() => {
            fetch(`${process.env.REACT_APP_API}/problemSets`)
              .then(res => res.json())
              .then((result) => {
                setProblemSets(result)
              },
                
                (error) => {
                  console.log(error);
                }
            )
        }, []);

        useEffect(() => {
            fetch(`${process.env.REACT_APP_API}/problemSets/author/11`)
              .then(res => res.json())
              .then((result) => {
                setYourProblemSets(result)
              },
                
                (error) => {
                  console.log(error);
                }
            )
        }, []);

    return (
        <div className="problemSets">
            <div className='conatiner'>

            <h2>Problem Sets</h2>
            <div className="problemSets__createNewProblem">
                <Button 
                    variant="contained"
                    color="primary"
                    onClick={() => history.push('/problemSets/new')}
                >
                            Create New Problem Set
                </Button>
            </div>
            <ShadowBox title="Your Problem Sets">
            {yourProblemSets.map(problemSet => {
                return (
                    <ProblemSet problemSet={problemSet} />
                )
            })}
            </ShadowBox>
            <ShadowBox title="Pursuit Problem Sets">
            {problemSets.map(problemSet => {
                return (
                    <ProblemSet problemSet={problemSet} />
                )
            })}
            </ShadowBox>
            <ShadowBox title="CodeTrack Problem Sets">
            {problemSets.map(problemSet => {
                return (
                    <ProblemSet problemSet={problemSet} />
                )
            })}
            </ShadowBox>
        </div>
        </div>
    );
}

export default ProblemSets;