import React, {useState} from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const CreateProblemSet = (props) => {

    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const creatorId = 11;

    const handleChange = (e) => {
        const {name, value} = e.target;

        switch(name) {
            case 'title':
                setTitle(value);
                break; 
            default:
                break;
        }
      }
    
      const handleSubmit = () => {
        setLoading(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
                        'Authorization': `Bearer ${ localStorage.getItem('accessToken')}`
                    },
            body: JSON.stringify({title, creatorId})
        };

        fetch(`${process.env.REACT_APP_API}/problemsets`, requestOptions)
        .then(res => res.json())
        .then((result) => { 
            setLoading(false);
            //toast message TODO
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })
    }

    return (
        <div className="createProblemSet">
            <div className='conatiner'>

            <h3>Create Custom Problem Set</h3>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                    variant="outlined"
                    fullWidth
                    id="title"
                    label="Problem Set Title"
                    name="title"
                    value={title}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                // className={classes.submit}
                onClick={handleSubmit}
                loading={loading}
                >
                Update
            </LoadingButton>
        </div>
        </div>
    )
}

export default CreateProblemSet;
