import React from 'react';

import './Toggle.scss';

function Toggle({toggleOn, setToggleOn}) {

    return (
        <label className='toggle'>
            <input type="checkbox" checked={toggleOn}  />
            <span className="toggle__switch" onClick={() => setToggleOn(!toggleOn)} />
        </label>
    );
}

export default Toggle;