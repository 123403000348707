import React from 'react';

function Videos(props) {
    return (
        <div className="videos">
            <div className='conatiner'>

            <h3>Videos</h3>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/cA4867KVME1KYRRwv-1sOE3ZPW4ulKIDBZd2NzuIUEAAZIuutqBgFeVVVIkmUF30.OI2rQ2DaFzDqzfEZ"
                    target="_blank"
                >
                    July 24th: Well of Ideas - Harder Version (90 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/GMH3XDzXVD1bbOddeOdizfBnZiZM1p5QO8MkXynJhkYNUPXK6OxvBdK6-Y_OGkxS.R9vi8NIyKO8dixM6?startTime=1659276216000"
                    target="_blank"
                >
                    July 31st: String Shortener (90 Min Class)
                </a>
            </div>
            <div style={{'margin-bottom': '20px'}}>
                <a 
                    href="https://us06web.zoom.us/rec/share/rD88bKcNGWhhZqQiJHJvijaAxapVQXyAlA-E3l4bUxPTikZJGfVjOTRSWXXstPZp.yHOmi88zFW8wUyIJ"
                    target="_blank"
                >
                    August 8th: Compress Strings (120 Min Class)
                </a>
            </div>
            <div>
                <a 
                    href="https://us06web.zoom.us/rec/play/OZB_QxaOtYOtiTr8iTuKd9P1_Fh0tWhKH0lpMPGU7nMhjwnOC0_dBp7K1uXOEaPNHpuAKYnzbJw2JkEC.-I7EiS3jNQnOrKQF?continueMode=true&_x_zm_rtaid=SPnvGoS8SligxGYApBDHkQ.1660527255702.354f24fd860e24168246293daa45bb29&_x_zm_rhtaid=180"
                    target="_blank"
                >
                    August 14th: Inside Out Strings and Rods and Rings  (90 Min Class)
                </a>
            </div>
        </div>
        </div>
    );
}

export default Videos;