import React from 'react';
import { Link } from 'react-router-dom';


function LandingYourFirstJob() {
    return (
        <div className="article">
            <div className="conatiner">
                <h2 className="article__title">Landing Your First Job as a Software Developer</h2>
                <p>You can be a great programmer! For those of us who have taken a non-traditional path to software or web development, we need to work extra hard to stand out in a crowded sea of candidates. This path is laid out to help you accentuate your strengths and compete favorably with candidates who may have a more classical computer science education</p>
                <p>Although we are the first to say that there is no right way to put yourself out into the job market, there are decidedly sub-optimal ways. We've prepared a guide for what you, as a new developer, should have as you actively look for your first internship or full-time position.</p>
                <p>Each point we lay out deserves its own article so we'll start with an overview here and you can click on whichever you are most interested in. We've laid these out roughly in an order of importance although it's all important! </p>
                <Link to="/one-great-project" >
                    <h5>1. One Great Application</h5>
                </Link>
                <p>This is one great project that you can show off with pride, able to talk in depth about the languages, frameworks and features.</p>
                <h5>2. One Page Resume</h5>
                <p>Given that most applicants do not have production experience, your resume should guide readers to your One Great Project where your work can speak for itself.</p>
                <h5>3. A Regular Practice of Solving Algorithms</h5>
                <p>This is a habit that should be practiced several times a week if not daily. We recommend you start with Codewars until you can easily solve 7 and 8 level Katas. Then move on to a site like LeetCode, working through easy problems. You should work towards the goal of being able to solve medium difficulty problems in 35-45 minutes.</p>
                <h5>4. An Ability to Answer a Wide Range of Questions</h5>
                <p>This is a large and ever growing area, but there are many common questions for new developers. You should work towards understanding and explaining many concepts.</p>
                <h5>5. A Strictly Typed Language</h5>
                <p>Most bootcamp grads learn Javascript. And that's great. Once you have a working knowledge of JavaScript, you should put in the time to learn a strictly-typed language like Java or possibly, TypeScript.</p>
                <h5>6. Contributing to Open Source</h5>
                <p>Contributing to open source is the thing you can do that is most like what most developers do on a daily basis in their job. Find a project that is accessible to you and make a commit.</p>
                <p>Ok, if you do these six things, always working to improve in each area, you are well on your way to landing a job. You can start with any of the six, but they are all important. It may seem daunting but consistency is key. Remember that you are seeking a position as a professional software developer. A professional understands their trade, produces good, clean work and can speak confidently and comfortably about their domain. </p>
            </div>
        </div>
    );
}

export default LandingYourFirstJob;